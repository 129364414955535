import { Suspense } from 'react'
import { FullLoading } from '@h4b-dev/components'

export type Props = {
  Component: React.ElementType
}

const SusEmpty = ({ Component }: Props) => (
  <Suspense fallback={<FullLoading />}>
    {Component ? <Component /> : null}
  </Suspense>
)

export default SusEmpty
