import { createRouter, Route } from '@storeon/router'
import { ActionTypeEnum } from '@pages-utils'

export const isRouteHidden = (route: Route<any>, hiddenRoutes: string[]) =>
  hiddenRoutes.some((hiddenRoute) => route[0].toString().includes(hiddenRoute))

export const getAvailableRoutes = () => {
  const hiddenRoutes = (h4benv.H4B_HIDDEN_ROUTES?.split(',') ?? []).filter(
    (route) => route !== ''
  )
  const routes: Route<any>[] = [
    [
      '/',
      () => ({
        page: 'real-time-dashboard',
        title: 'Dashboard',
        menuActive: 'real-time-dashboard',
        props: {
          additionalPermissions: [ActionTypeEnum.read]
        }
      })
    ],
    [
      '/payment-requests*',
      () => ({
        page: 'payment-requests',
        title: 'Solicitudes de pago',
        menuActive: 'payment-requests',
        props: {
          additionalPermissions: [
            ActionTypeEnum.update,
            ActionTypeEnum.download
          ]
        }
      })
    ],
    [
      '/support-cases*',
      () => ({
        page: 'support-cases',
        title: 'Casos de soporte',
        menuActive: 'support-cases',
        props: {
          additionalPermissions: [
            ActionTypeEnum.update,
            ActionTypeEnum.download
          ]
        }
      })
    ],
    [
      '/charge-requests*',
      () => ({
        page: 'charge-requests',
        title: 'Historial de Cargos',
        menuActive: 'charge-requests',
        props: {
          additionalPermissions: [
            ActionTypeEnum.create,
            ActionTypeEnum.update,
            ActionTypeEnum.download
          ]
        }
      })
    ],
    [
      '/chargeback-cases*',
      () => ({
        page: 'chargeback-cases',
        title: 'Contracargos',
        menuActive: 'chargeback-cases',
        props: {
          additionalPermissions: [
            ActionTypeEnum.send,
            ActionTypeEnum.update,
            ActionTypeEnum.upload
          ]
        }
      })
    ],
    [
      '/physical-acq-charges',
      () => ({
        page: 'physical-acq-charges',
        title: 'Adquirencia física',
        menuActive: 'physical-acq-charges'
      })
    ],
    [
      '/customers*',
      () => ({
        page: 'customers',
        title: 'Clientes',
        menuActive: 'customers',
        props: {
          additionalPermissions: [ActionTypeEnum.download]
        }
      })
    ],
    [
      '/refund-requests*',
      () => ({
        page: 'refund-requests',
        title: 'Solicitudes de reintegro',
        menuActive: 'refund-requests',
        props: {
          additionalPermissions: [ActionTypeEnum.update]
        }
      })
    ],
    [
      '/users-management',
      () => ({
        page: 'users-management',
        title: 'Administración de usuarios',
        menuActive: '',
        props: {
          additionalPermissions: [ActionTypeEnum.create, ActionTypeEnum.update]
        }
      })
    ],
    [
      '/roles-management',
      () => ({
        page: 'roles-management',
        title: 'Administración de roles',
        menuActive: '',
        props: {
          additionalPermissions: [ActionTypeEnum.create, ActionTypeEnum.update]
        }
      })
    ],
    [
      '/error-messages-management',
      () => ({
        page: 'error-messages-management',
        title: 'Administración de mensajes de error',
        menuActive: '',
        props: {
          additionalPermissions: [ActionTypeEnum.create, ActionTypeEnum.update]
        }
      })
    ],
    [
      '/logout',
      () => ({
        page: 'logout',
        title: 'Logout',
        menuActive: '',
        layout: 'empty'
      })
    ],
    [
      '/authorize',
      () => ({
        page: 'authorization',
        title: 'authorization',
        menuActive: '',
        layout: 'empty'
      })
    ]
  ]

  const filteredRoutes = routes.filter(
    (route) => !isRouteHidden(route, hiddenRoutes)
  )

  return filteredRoutes
}

export default createRouter(getAvailableRoutes())
