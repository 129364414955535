/* eslint-disable implicit-arrow-linebreak */
import { useState, useEffect } from 'react'
import { useStoreon } from 'storeon/react'
import { routerKey } from '@storeon/router'
import * as Sentry from '@sentry/react'
import SmartlookClient from 'smartlook-client'
import { MonitorModulesProvider } from '@h4b-dev/payments-monitor-modules'
import { useAuth, useAuthContext } from '@hooks'
import { getActionsByView } from '@pages-utils'
import { SusMainMenu, SusEmpty, SusModal } from '../layouts'
import getPageComponent from './pages'

const Page = () => {
  const { user } = useAuth()
  const {
    user: { token },
    parsedToken
  } = useAuthContext()
  const { [routerKey]: route } = useStoreon(routerKey)
  const [hasError, setHasError] = useState(false)
  const [hiddenModules, setHiddenModules] = useState<any>({})

  const userActionsInThisView = getActionsByView({
    userPermissions: parsedToken?.permissions ?? [],
    view: route.match?.page ?? ''
  })

  useEffect(() => {
    if (!window.unhandledrejection) {
      window.unhandledrejection = (event: any) => {
        console.error(
          `UNHANDLED PROMISE REJECTION: ${JSON.stringify(event.reason)}`,
          event
        )
        // We set has error because we cant render from the event listener
        setHasError(true)
      }
    }
  }, [hasError])

  useEffect(() => {
    if (user && user.userData?.email && !import.meta.env.DEV) {
      Sentry.getCurrentScope().setExtra(
        'environment',
        parsedToken?.claims.enviroment ?? 'development'
      )
      Sentry.getCurrentScope().setUser({ email: user.userData.email })
      SmartlookClient.identify(user.userData.sub, user.userData)
    }
  }, [user])

  useEffect(() => {
    const hiddenRoutes = (h4benv.H4B_HIDDEN_ROUTES?.split(',') ?? []).filter(
      (route) => route !== ''
    )
    let hiddenRoutesObject: any = {}

    hiddenRoutes.forEach((route: string) => {
      hiddenRoutesObject = { ...hiddenRoutesObject, [route]: true }
    })

    setHiddenModules(hiddenRoutesObject)
  }, [])

  if (hasError) return <SusEmpty Component={getPageComponent('serverError')} />

  // auth0 has not loaded, avoid unnecesary rendering
  if (!user.isLoaded) return null

  if (route.path === '/authorize')
    return <SusEmpty Component={getPageComponent('authorization')} />

  if (route.path === '/login' || !user.isAuthenticated)
    return <SusEmpty Component={getPageComponent('login')} />

  if (!route.match) return <SusEmpty Component={getPageComponent('notFound')} />

  if (!userActionsInThisView.read)
    return <SusEmpty Component={getPageComponent('notAuthorized')} />

  const Component = getPageComponent(route.match.page)
  const isModal = typeof route.match.modal !== 'undefined'

  let Layout
  switch (route.match.layout) {
    case 'empty':
      Layout = <SusEmpty Component={Component} />
      break
    default:
      Layout = (
        <SusMainMenu
          Component={Component}
          route={route}
          user={user}
          isSlave={false}
          parsedToken={parsedToken}
          userActionsInThisView={userActionsInThisView}
        />
      )
      break
  }

  if (isModal) {
    const { modal } = route.match
    const ModalComponent = getPageComponent(modal.page)

    if (!ModalComponent)
      return <SusEmpty Component={getPageComponent('notFound')} />

    return (
      <MonitorModulesProvider token={token} hiddenModules={hiddenModules}>
        {Layout}
        <SusModal
          Component={ModalComponent}
          title={modal.title}
          returnTo={modal.returnTo}
          props={modal.props}
          returnComponent={modal.returnComponent}
        />
      </MonitorModulesProvider>
    )
  }

  return (
    <MonitorModulesProvider token={token} hiddenModules={hiddenModules}>
      {Layout}
    </MonitorModulesProvider>
  )
}

export default Page
