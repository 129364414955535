import { css } from '@linaria/core'

const styles = css`
  & > div {
    /* Side menu */
    & > div {
      & .container-menu {
        @apply dark:bg-custom-dark-500 dark:border-custom-dark-300 dark:text-white;

        & .menu {
          @apply dark:bg-custom-dark-400;
        }

        & .menu-logo {
          @apply mt-10;

          & > svg {
            & > path {
              @apply dark:fill-white;
            }

            & > line {
              @apply dark:stroke-white;
            }
          }
        }

        & .items {
          & a {
            &.active {
              @apply bg-black dark:bg-white/10;
            }

            .menu-item-label {
              @apply normal-case text-xs;
            }
          }
        }
      }

      & .collapse-toggle {
        @apply dark:bg-custom-loader-100 dark:border-custom-dark-300;
      }
    }

    & .content {
      @apply dark:bg-transparent;

      & > header {
        @apply hidden;
      }

      & > nav {
        @apply dark:bg-custom-dark-500 dark:border-custom-dark-300;

        .menu-btn__burger,
        .menu-btn__burger:after,
        .menu-btn__burger:before {
          @apply bg-custom-primary-500 dark:bg-white/80 !important;
        }

        & .menuicon {
          @apply flex md:hidden;

          &:hover {
            @apply dark:bg-white/10 !important;
            transform: scale(1.05);
          }
        }

        & .right-section > .user-menu > .account-submenu {
          @apply dark:bg-custom-dark-400 dark:border-custom-dark-300 dark:text-white;

          & > li {
            &:hover {
              @apply dark:bg-white/5;
            }

            &:last-child {
              @apply dark:border-t-custom-dark-300;
            }

            & > a {
              & > svg > path,
              & > svg > g {
                @apply fill-main-gray-400 dark:fill-white/80;
              }

              & > span {
                @apply dark:text-white;
              }
            }
          }
        }
      }

      & > main {
        @apply py-8 dark:bg-custom-dark-400;
      }
    }
  }
`

export default styles
