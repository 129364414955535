import { StoreContext } from 'storeon/react'
import * as Sentry from '@sentry/react'
import SmartlookClient from 'smartlook-client'
import store from '@store'
import { AuthProvider, QueryProvider } from '@hooks'
import Page from './pages/'

import '@h4b-dev/components/dist/styles.css'
import '@h4b-dev/payments-monitor-modules/dist/styles.css'
import './styles/global.css'

const App = () => (
  <StoreContext.Provider value={store}>
    <AuthProvider>
      <QueryProvider>
        <Page />
      </QueryProvider>
    </AuthProvider>
  </StoreContext.Provider>
)

if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'sandbox'
) {
  // Initialize analytics here
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: h4benv.H4B_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
  SmartlookClient.init(h4benv.H4B_SMARTLOOK_KEY)
}

export default App
