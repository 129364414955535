import { Suspense, useMemo } from 'react'
import classNames from 'classnames'
import { Layouts, FullLoading } from '@h4b-dev/components'
import { navigate } from '@store'
import { useSuscriptionTrackerByRoute } from '@h4b-dev/payments-monitor-modules'
import {
  MenuItem,
  AccountSubMenuItem,
  Auth0UserData,
  ParsedToken,
  ViewActionsPermissions
} from '@custom-types'
import { getActionsByView } from '@pages-utils'
import { UIModeSwitch } from '@/components'

import N1coMenuLogo from './icons/logo.svg?react'
import N1coMenuLogoMini from './icons/logo-mini.svg?react'
import YummyMenuLogo from './icons/yummy-black.svg?react'
import YummyMenuLogoMini from './icons/mini-yummy-black.svg?react'
import UsersIcon from '/public/svg/users.svg?react'
import ShieldIcon from '/public/svg/shield.svg?react'
import ErrorMessageIcon from '/public/svg/error-message.svg?react'

import styles from './styles'

type Props = {
  Component: React.ElementType
  route: any
  user: {
    isAuthenticated: boolean
    isLoaded: boolean
    token: string
    userData: Auth0UserData
  }
  isSlave: boolean
  userActionsInThisView: ViewActionsPermissions
  parsedToken?: ParsedToken
}

const {
  H4B_AUTH_USER_ROLES: USER_ROLES_PATH,
  H4B_CURRENT_BRAND: CURRENT_BRAND
} = h4benv

const menuItems: MenuItem[] = [
  {
    key: 'real-time-dashboard',
    label: 'Dashboard',
    target: '/',
    iconUrl: '/svg/graphics.svg'
  },
  {
    key: 'customers',
    label: 'Clientes',
    target: '/customers',
    iconUrl: '/svg/users.svg'
  },
  {
    key: 'payment-requests',
    label: 'Solicitudes de pago',
    target: '/payment-requests',
    iconUrl: '/svg/search-payment.svg'
  },
  {
    key: 'charge-requests',
    label: 'Historial de cargos',
    target: '/charge-requests',
    iconUrl: '/svg/card-atm.svg'
  },
  {
    key: 'physical-acq-charges',
    label: 'Adquirencia física',
    target: '/physical-acq-charges',
    iconUrl: '/svg/pos.svg'
  },
  {
    key: 'chargeback-cases',
    label: 'Contracargos',
    target: '/chargeback-cases',
    iconUrl: '/svg/chargeback.svg'
  },
  {
    key: 'refund-requests',
    label: 'Reintegros',
    target: '/refund-requests',
    iconUrl: '/svg/refund.svg'
  },
  {
    key: 'support-cases',
    label: 'Casos de soporte',
    target: '/support-cases',
    iconUrl: '/svg/support.svg'
  }
]

const accountSubmenuItems: AccountSubMenuItem[] = [
  {
    key: 'users-management',
    label: 'Usuarios',
    target: '/users-management',
    icon: UsersIcon
  },
  {
    key: 'roles-management',
    label: 'Roles',
    target: '/roles-management',
    icon: ShieldIcon
  },
  {
    key: 'error-messages-management',
    label: 'Mensajes de error',
    target: '/error-messages-management',
    icon: ErrorMessageIcon
  }
]

const getMenuLogo = () => {
  if (CURRENT_BRAND === 'yummy') return YummyMenuLogo
  return N1coMenuLogo
}

const getMenuLogoMini = () => {
  if (CURRENT_BRAND === 'yummy') return YummyMenuLogoMini
  return N1coMenuLogoMini
}

interface GetOnlyAvailableItemsProps<T> {
  userPermissions: string[]
  items: T[]
}

const getOnlyAvailableItems = <T extends MenuItem | AccountSubMenuItem>({
  userPermissions,
  items
}: GetOnlyAvailableItemsProps<T>): T[] =>
  items.filter((item) => {
    const actionsForThisItem = getActionsByView({
      userPermissions,
      view: item.key
    })
    const haveReadPermission = actionsForThisItem.read !== undefined
    return haveReadPermission
  })

const isRouteHidden = (route: string, hiddenRoutes: string[]) =>
  hiddenRoutes.some((hiddenRoute) => route.includes(hiddenRoute))

const SusMainMenu: React.FC<Props> = ({
  Component,
  route,
  user,
  parsedToken,
  userActionsInThisView,
  isSlave = false
}) => {
  const userRoles = user?.userData?.[USER_ROLES_PATH] || []

  const { getRealTimeProps } = useSuscriptionTrackerByRoute({ route })
  const menuProps = useMemo(() => {
    const hiddenRoutes = (h4benv.H4B_HIDDEN_ROUTES?.split(',') ?? []).filter(
      (route) => route !== ''
    )
    const filteredMenuItems = menuItems.filter(
      (item) => !isRouteHidden(item.key, hiddenRoutes)
    )
    const itemData = getOnlyAvailableItems<MenuItem>({
      userPermissions: parsedToken?.permissions ?? [],
      items: filteredMenuItems
    })

    return {
      MenuLogo: getMenuLogo(),
      MenuLogoMini: getMenuLogoMini(),
      itemData,
      activeItem: route.path,
      userRoles
    }
  }, [menuItems])
  const topBarProps = useMemo(() => {
    const accountSubmenuItems2 = getOnlyAvailableItems<AccountSubMenuItem>({
      userPermissions: parsedToken?.permissions ?? [],
      items: accountSubmenuItems
    })

    return {
      selects: [],
      accountAvatarProps: {
        source: user.userData.picture || '',
        userName: user.userData.name || 'No session'
      },
      userRoles,
      accountSubmenuItems: accountSubmenuItems2
    }
  }, [user, accountSubmenuItems])

  return (
    <div className={classNames(styles, 'main-menu')}>
      <Layouts.MainMenu
        title={route.match.title}
        menuProps={menuProps}
        topBarProps={topBarProps}
        navActions={
          route.match.navActions
            ? route.match.navActions.map(
                (action: { target: string | undefined }) => ({
                  ...action,
                  onClick: () => navigate(action.target)
                })
              )
            : []
        }
        disabledActions={isSlave}
        breadcrumbPaths={route.match.breadcrumbs}
        activeItem={route.match.menuActive}
      >
        <UIModeSwitch />
        <Suspense fallback={<FullLoading />}>
          {Component ? (
            <Component
              {...{
                ...route.match.props,
                userActionsInThisView,
                ...getRealTimeProps()
              }}
            />
          ) : null}
        </Suspense>
      </Layouts.MainMenu>
    </div>
  )
}

export default SusMainMenu
